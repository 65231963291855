"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@uirouter/angular");
const mtcf_report_component_1 = require("./mtcf-report/mtcf-report.component");
const residentCards_report_component_1 = require("./residentCards-report/residentCards-report.component");
const care_minutes_report_component_1 = require("./care-minutes-report/care-minutes-report.component");
const rooms_report_component_1 = require("./rooms-report/rooms-report.component");
exports.states = [
    {
        name: "mtcfReport",
        url: "/reporting/mtcf",
        component: mtcf_report_component_1.MtcfsReportComponent
    },
    {
        name: "residentCardsReport",
        url: "/reporting/residentCards",
        component: residentCards_report_component_1.ResidentCardsReportComponent
    },
    {
        name: "careMinutesReport",
        url: "/reporting/careMinutes",
        component: care_minutes_report_component_1.CareMinutesReportComponent
    },
    {
        name: "roomsReport",
        url: "/reporting/rooms",
        component: rooms_report_component_1.RoomsReportComponent
    }
];
