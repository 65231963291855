"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
require("../dtos/facility-dto");
require("../dtos/region-dto");
require("../dtos/tag-dto");
const _ = require("underscore");
require("../../common/dto/batch-result");
const i0 = require("@angular/core");
/**
 * Performs facility related API requests.
 */
class FacilityService {
    /**
     * Initializes a new instance of the FundingService class.
     *
     * @param dataService The data service.
     */
    constructor(dataService) {
        this.dataService = dataService;
        /**
         * Gets a facility from the API.
         *
         * @param facilityId The facility id.
         * @returns A Promise that will be resolved or rejected with a @see FacilityDto object.
         */
        this.getFacility = (facilityId) => {
            return this.dataService.get(`facilities/${facilityId}`, true);
        };
        /**
         * Gets a list of regions from the API.
         *
         * @returns A Promise that will be resolved or rejected with a collection of @see FacilityDto objects.
         */
        this.getRegions = () => {
            return this.dataService.get('facilities/regions', true);
        };
        /**
         * Saves a facility to the API.
         *
         * @returns A Promise that will be resolved or rejected.
         */
        this.saveFacility = (facilityDto) => {
            return this.dataService.post('facilities/save', facilityDto);
        };
        /**
         * Saves lead tags to the API.
         *
         * @returns A Promise that will be resolved or rejected.
         */
        this.saveTags = (tagDtos) => {
            return this.dataService.post('facilities/save-tags', tagDtos);
        };
        this.getAllTagsForFacilityId = (facilityId, skip, take) => {
            const parameterObject = _.extend({}, {
                skip: skip,
                take: take
            });
            return this.dataService.get(`facilities/${facilityId}/tags`, true, parameterObject);
        };
    }
}
exports.FacilityService = FacilityService;
FacilityService.ɵfac = function FacilityService_Factory(t) { return new (t || FacilityService)(i0.ɵɵinject('dataService')); };
FacilityService.ɵprov = i0.ɵɵdefineInjectable({ token: FacilityService, factory: FacilityService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(FacilityService, [{
        type: core_1.Injectable
    }], function () { return [{ type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['dataService']
            }] }]; }, null); })();
