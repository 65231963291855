"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const common_1 = require("@angular/common");
const static_1 = require("@angular/upgrade/static");
const show_hide_password_directive_1 = require("./directives/show-hide-password.directive");
const enquiryAccessibilityRequirementDescription_pipe_1 = require("./pipes/enquiryAccessibilityRequirementDescription.pipe");
const dnaRSnackBar_service_1 = require("./dna-r/dnaRSnackBar.service");
const core_2 = require("@angular/material/core");
const dnaR_paginator_component_1 = require("./components/dnaR-paginator/dnaR-paginator.component");
const button_1 = require("@angular/material/button");
const dnaR_back_to_top_component_1 = require("./components/dnaR-back-to-top/dnaR-back-to-top.component");
const dnaR_popover_directive_1 = require("./directives/dnaR-popover/dnaR-popover.directive");
const i0 = require("@angular/core");
// Downgrade and register items in AngularJs
angular.module('app')
    .factory('enquiryAccessibilityRequirementDescription', static_1.downgradeInjectable(enquiryAccessibilityRequirementDescription_pipe_1.EnquiryAccessibilityRequirementDescription));
class SharedModule {
}
exports.SharedModule = SharedModule;
SharedModule.ɵmod = i0.ɵɵdefineNgModule({ type: SharedModule });
SharedModule.ɵinj = i0.ɵɵdefineInjector({ factory: function SharedModule_Factory(t) { return new (t || SharedModule)(); }, providers: [
        { provide: 'dataService', useFactory: (i) => { return i.get('data.service'); }, deps: ['$injector'] },
        dnaRSnackBar_service_1.DnaRSnackBarService,
        enquiryAccessibilityRequirementDescription_pipe_1.EnquiryAccessibilityRequirementDescription,
        core_2.MatRipple
    ], imports: [[
            forms_1.FormsModule,
            common_1.CommonModule,
            button_1.MatButtonModule
        ],
        forms_1.FormsModule,
        common_1.CommonModule] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(SharedModule, { declarations: [show_hide_password_directive_1.ShowHidePasswordDirective,
        enquiryAccessibilityRequirementDescription_pipe_1.EnquiryAccessibilityRequirementDescription,
        dnaR_paginator_component_1.DnaRPaginator,
        dnaR_back_to_top_component_1.DnaRBackToTop,
        dnaR_popover_directive_1.DnaRPopoverDirective], imports: [forms_1.FormsModule,
        common_1.CommonModule,
        button_1.MatButtonModule], exports: [forms_1.FormsModule,
        common_1.CommonModule,
        show_hide_password_directive_1.ShowHidePasswordDirective,
        enquiryAccessibilityRequirementDescription_pipe_1.EnquiryAccessibilityRequirementDescription,
        dnaR_paginator_component_1.DnaRPaginator,
        dnaR_back_to_top_component_1.DnaRBackToTop,
        dnaR_popover_directive_1.DnaRPopoverDirective] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(SharedModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [
                    forms_1.FormsModule,
                    common_1.CommonModule,
                    button_1.MatButtonModule
                ],
                providers: [
                    { provide: 'dataService', useFactory: (i) => { return i.get('data.service'); }, deps: ['$injector'] },
                    dnaRSnackBar_service_1.DnaRSnackBarService,
                    enquiryAccessibilityRequirementDescription_pipe_1.EnquiryAccessibilityRequirementDescription,
                    core_2.MatRipple
                ],
                declarations: [
                    show_hide_password_directive_1.ShowHidePasswordDirective,
                    enquiryAccessibilityRequirementDescription_pipe_1.EnquiryAccessibilityRequirementDescription,
                    dnaR_paginator_component_1.DnaRPaginator,
                    dnaR_back_to_top_component_1.DnaRBackToTop,
                    dnaR_popover_directive_1.DnaRPopoverDirective
                ],
                exports: [
                    forms_1.FormsModule,
                    common_1.CommonModule,
                    show_hide_password_directive_1.ShowHidePasswordDirective,
                    enquiryAccessibilityRequirementDescription_pipe_1.EnquiryAccessibilityRequirementDescription,
                    dnaR_paginator_component_1.DnaRPaginator,
                    dnaR_back_to_top_component_1.DnaRBackToTop,
                    dnaR_popover_directive_1.DnaRPopoverDirective
                ]
            }]
    }], null, null); })();
