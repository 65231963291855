"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const mtcf_report_component_1 = require("./mtcf-report/mtcf-report.component");
const residentCards_report_component_1 = require("./residentCards-report/residentCards-report.component");
const reporting_states_1 = require("./reporting.states");
const care_minutes_report_component_1 = require("./care-minutes-report/care-minutes-report.component");
const rooms_report_component_1 = require("./rooms-report/rooms-report.component");
const shared_module_1 = require("../common/shared.module");
const angular_hybrid_1 = require("@uirouter/angular-hybrid");
const devextreme_angular_1 = require("devextreme-angular");
const platform_browser_1 = require("@angular/platform-browser");
const reporting_service_1 = require("./reporting.service");
const dnaR_datePicker_module_1 = require("../common/components/dnaR-datePicker/dnaR-datePicker.module");
const dnaR_dropdown_multiSelect_module_1 = require("../common/components/dnaR-dropdown-multiSelect/dnaR-dropdown-multiSelect.module");
const i0 = require("@angular/core");
const i1 = require("@uirouter/angular-hybrid");
class ReportingModule {
}
exports.ReportingModule = ReportingModule;
ReportingModule.ɵmod = i0.ɵɵdefineNgModule({ type: ReportingModule });
ReportingModule.ɵinj = i0.ɵɵdefineInjector({ factory: function ReportingModule_Factory(t) { return new (t || ReportingModule)(); }, providers: [reporting_service_1.ReportingService], imports: [[
            shared_module_1.SharedModule,
            platform_browser_1.BrowserModule,
            devextreme_angular_1.DxDataGridModule,
            devextreme_angular_1.DxSelectBoxModule,
            devextreme_angular_1.DxCheckBoxModule,
            devextreme_angular_1.DxValidatorModule,
            devextreme_angular_1.DxDateBoxModule,
            angular_hybrid_1.UIRouterUpgradeModule.forChild({ states: reporting_states_1.states }),
            dnaR_datePicker_module_1.DnaRDatePickerModule,
            dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule
        ]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(ReportingModule, { declarations: [mtcf_report_component_1.MtcfsReportComponent,
        residentCards_report_component_1.ResidentCardsReportComponent,
        care_minutes_report_component_1.CareMinutesReportComponent,
        rooms_report_component_1.RoomsReportComponent], imports: [shared_module_1.SharedModule,
        platform_browser_1.BrowserModule,
        devextreme_angular_1.DxDataGridModule,
        devextreme_angular_1.DxSelectBoxModule,
        devextreme_angular_1.DxCheckBoxModule,
        devextreme_angular_1.DxValidatorModule,
        devextreme_angular_1.DxDateBoxModule, i1.UIRouterUpgradeModule, dnaR_datePicker_module_1.DnaRDatePickerModule,
        dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ReportingModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [
                    shared_module_1.SharedModule,
                    platform_browser_1.BrowserModule,
                    devextreme_angular_1.DxDataGridModule,
                    devextreme_angular_1.DxSelectBoxModule,
                    devextreme_angular_1.DxCheckBoxModule,
                    devextreme_angular_1.DxValidatorModule,
                    devextreme_angular_1.DxDateBoxModule,
                    angular_hybrid_1.UIRouterUpgradeModule.forChild({ states: reporting_states_1.states }),
                    dnaR_datePicker_module_1.DnaRDatePickerModule,
                    dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule
                ],
                providers: [reporting_service_1.ReportingService],
                declarations: [
                    mtcf_report_component_1.MtcfsReportComponent,
                    residentCards_report_component_1.ResidentCardsReportComponent,
                    care_minutes_report_component_1.CareMinutesReportComponent,
                    rooms_report_component_1.RoomsReportComponent
                ]
            }]
    }], null, null); })();
